'use strict';

const carousels = require('./carousels');
const DELIVERY_METHODS = {
    DOMICILIO: 'Delivery',
    LOCKER: 'ClickAndCollect',
    TIENDA: 'InStorePickUp'
};

const clearTimeSlotCarrousel = (shippingMethod) => {
    const TYPE = {
        Delivery: 'delivery',
        ClickAndCollect: 'collect',
        InStorePickUp: 'shop'
    };
    const text = $(`div[data-shipping-method="${shippingMethod}"]`).data('noslots');
    $(`div[data-slot-type="${TYPE[shippingMethod]}"]`).html(`
        <div class="tab-content__grid-right-box">
            <div class="timeslot-table no-slots">
                <p>${text}</p>
            </div>
        </div>
    `);
};

/**
 * Retrieves the selected shipment and time slot data.
 * Returns an object with null values if the selected method is undefined or JSON parsing fails.
 *
 * @returns {Object} An object with:
 * - {Object|null} selectedShipment - The selected shipment data.
 * - {Object|null} selectedTimeslot - The parsed time slot data.
 */
const getSelectedShipmentAndSlotData = () => {
    const selectedShipment = $('div[data-selected-shipment]').length ? $('div[data-selected-shipment]').data('selected-shipment') : null;
    const emptyData = {
        selectedShipment: null,
        selectedTimeslot: null
    };

    if (selectedShipment && typeof selectedShipment.selectedMethod === 'undefined') {
        return emptyData;
    }

    let selectedTimeslot = {};
    try {
        selectedTimeslot = JSON.parse(selectedShipment.slot);
    } catch (error) {
        return emptyData;
    }

    return {
        selectedShipment,
        selectedTimeslot
    };
};

/**
 * Sends a POST request to the specified URL to reload delivery time slots based on the provided postal code.
 * Updates the HTML content of the delivery slot section with the returned template and initializes a swiper if included.
 * @param {string} url - The URL to which the POST request will be sent.
 * @param {string} postalCode - The postal code used to fetch specific delivery time slots.
 */
const reloadDeliveryTimeSlot = (url, postalCode) => {
    if (!url || !postalCode) {
        return;
    }

    $.ajax({
        url: url,
        method: 'POST',
        data: {
            postalCode: postalCode,
            jsselector: 'js-slots-delivery',
            type: 'delivery'
        },
        success: (data) => {
            if (data.template) {
                $('div[data-slot-type="delivery"]').html(`<div class="tab-content__grid-right-box">${data.template}</div>`);
                $('#timeslot-selected-timeslot').val(JSON.stringify(data.slot));
                $('#timeslot-selected-timeslot').data('slot', JSON.stringify(data.slot));
                $('#timeslot-shipping-method').val(data.selectedShipment);

                if (data.swiper) {
                    const swiperContainer = document.querySelector('div[data-slot-type="delivery"] .js-swiper');
                    carousels.methods.initializeSwiper(swiperContainer);
                }
            }
        }
    });
};

/**
 * Sends a POST request to the specified URL to reload locker time slots based on the provided lockerId
 * Updates the HTML content of the locker slot section with the returned template and initializes a swiper if included.
 * @param {string} url - The URL to which the POST request will be sent.
 * @param {object} param - The params with the postal code used to fetch specific delivery time slots and the id of a store.
 * @param {function} callback - callback to be executed once timeslot is printed
 */
const reloadClickAndCollectTimeSlot = (url, param, callback) => {
    if (!param || !param.id || !url) {
        clearTimeSlotCarrousel(DELIVERY_METHODS.LOCKER);
        return;
    }

    $.ajax({
        url: url,
        method: 'POST',
        data: {
            id: param.id,
            postalCode: param.postalCode,
            jsselector: 'js-slots-collect',
            type: 'lockers'
        },
        success: (data) => {
            if (data.template) {
                $('div[data-slot-type="collect"]').html(`<div class="tab-content__grid-right-box">${data.template}</div>`);
                $('#timeslot-selected-timeslot').val(JSON.stringify(data.slot));
                $('#timeslot-selected-timeslot').data('slot', JSON.stringify(data.slot));
                $('#timeslot-shipping-method').val(data.selectedShipment);

                if (data.swiper) {
                    const swiperContainer = document.querySelector('div[data-slot-type="collect"] .js-swiper');
                    carousels.methods.initializeSwiper(swiperContainer);
                }

                if (data.placeName) {
                    $('.place-name').text(data.placeName);
                }

                if (callback) {
                    callback();
                }
            }
        }
    });
};

/**
 * Sends a POST request to the specified URL to reload store time slots based on the provided storeId
 * Updates the HTML content of the store slot section with the returned template and initializes a swiper if included.
 * @param {string} url - The URL to which the POST request will be sent.
 * @param {object} param - The params with the postal code used to fetch specific delivery time slots and the id of a store.
 * @param {function} callback - callback to be executed once timeslot is printed
 */
const reloadInStorePickUpTimeSlot = (url, param, callback) => {
    if (!param || !param.id || !url) {
        clearTimeSlotCarrousel(DELIVERY_METHODS.TIENDA);
        return;
    }

    $.ajax({
        url: url,
        method: 'POST',
        data: {
            id: param.id,
            postalCode: param.postalCode,
            jsselector: 'js-slots-shop',
            type: 'pickup'
        },
        success: (data) => {
            if (data.template) {
                $('div[data-slot-type="shop"]').html(`<div class="tab-content__grid-right-box">${data.template}</div>`);
                $('#timeslot-selected-timeslot').val(JSON.stringify(data.slot));
                $('#timeslot-selected-timeslot').data('slot', JSON.stringify(data.slot));
                $('#timeslot-shipping-method').val(data.selectedShipment);

                if (data.swiper) {
                    const swiperContainer = document.querySelector('div[data-slot-type="shop"] .js-swiper');
                    carousels.methods.initializeSwiper(swiperContainer);
                }

                if (callback) {
                    callback();
                }

                if (data.placeName) {
                    $('.place-name').text(data.placeName);
                }
            }
        }
    });
};

const getStoreData = () => window.storeData ? window.storeData : storeData;
const getLockerData = () => window.lockerData ? window.lockerData : lockerData;

/**
 * Clears the selected time slot and shipping method data from the UI.
 */
const clearSlotData = () => {
    $('#timeslot-selected-timeslot').val('');
    $('#timeslot-selected-timeslot').data('slot', '');
    $('#timeslot-shipping-method').val('');
};

/**
 * Initializes event handlers for in-store pickup and click and collect selectors.
 */
const updateShippingSelectors = (shippingMethod) => {
    if (!shippingMethod) {
        return;
    }

    const stateCodeSelector = shippingMethod === 'stores' ? $('#instorepickup-state') : $('#clickcollect-state');
    const citySelector = shippingMethod === 'stores' ? $('#instorepickup-city') : $('#clickcollect-city');
    const storeSelector = shippingMethod === 'stores' ? $('#instorepickup-store') : $('#clickcollect-locker');
    const data = shippingMethod === 'stores' ? getStoreData() : getLockerData();

    if (stateCodeSelector) {
        stateCodeSelector.on('change', (e) => {
            const currentTarget = $(e.currentTarget);
            const selectedStateCode = stateCodeSelector.val();

            citySelector.empty();
            citySelector.append($('<option>').val('').text(citySelector.data('default')));
            storeSelector.empty();
            storeSelector.append($('<option>').val('').text(storeSelector.data('default')));

            clearSlotData();

            if (currentTarget.attr('id').startsWith('clickcollect')) {
                reloadClickAndCollectTimeSlot($(currentTarget).data('url'), '');
            } else {
                reloadInStorePickUpTimeSlot($(currentTarget).data('url'), '');
            }

            if (selectedStateCode) {
                const cities = Object.keys(data[selectedStateCode]);
                $.each(cities, (index, city) => {
                    citySelector.append($('<option>').val(city).text(city));
                });
            }
        });
    }

    if (citySelector) {
        citySelector.on('change', (e) => {
            const currentTarget = $(e.currentTarget);
            const selectedCity = citySelector.val();
            const selectedStateCode = stateCodeSelector.val();

            storeSelector.empty();
            storeSelector.append($('<option>').val('').text(storeSelector.data('default')));

            clearSlotData();
            if (currentTarget.attr('id').startsWith('clickcollect')) {
                reloadClickAndCollectTimeSlot($(currentTarget).data('url'), '');
            } else {
                reloadInStorePickUpTimeSlot($(currentTarget).data('url'), '');
            }

            if (selectedCity) {
                const storesInCity = data[selectedStateCode][selectedCity];
                $.each(storesInCity, (index, store) => {
                    storeSelector.append(
                        $('<option>')
                            .val(store.ID)
                            .text(store.name)
                            .attr('data-cp', store.postalCode)
                    );
                });
            }
        });
    }

    if (storeSelector) {
        storeSelector.on('change', (e) => {
            e.stopPropagation();
            const currentTarget = $(e.currentTarget);
            const dataCp = currentTarget.find('option:selected').data('cp');
            if (currentTarget.val() !== '') {
                $('#selectSlotModal .invalid-feedback').removeClass('u-show');
                if (currentTarget.attr('id').endsWith('store')) {
                    reloadInStorePickUpTimeSlot($(currentTarget).data('url'), { id: currentTarget.val(), postalCode: dataCp }, () => {
                        if ($('.js-shop-message').length) {
                            const $message = $('.js-shop-message');
                            $('span', $message).text($('option:selected', currentTarget).text());
                        }
                    });
                } else {
                    reloadClickAndCollectTimeSlot($(currentTarget).data('url'), { id: currentTarget.val(), postalCode: dataCp }, () => {
                        if ($('.js-collect-message').length) {
                            const $message = $('.js-collect-message');
                            $('span', $message).text($('option:selected', currentTarget).text());
                        }
                    });
                }
            } else {
                clearSlotData();

                if (currentTarget.attr('id').startsWith('clickcollect')) {
                    reloadClickAndCollectTimeSlot($(currentTarget).data('url'), '');
                } else {
                    reloadInStorePickUpTimeSlot($(currentTarget).data('url'), '');
                }
            }
        });
    }
};

/**
 * Reloads the time slot form for a new address based on the provided postal code element's value.
 * @param {jQuery} postalCodeElement - The jQuery element containing the postal code to use for reloading the time slot form.
 */
const reloadTimeSlotNewAddressForm = (postalCodeElement) => {
    if (postalCodeElement.val().length === 5) {
        $('#timeslot-selected-timeslot').val('');
        reloadDeliveryTimeSlot(postalCodeElement.data('url'), postalCodeElement.val());
    }
};

/**
 * Preloads the timeslot selection based on the provided section.
 * @param {Object} section - The section object which determines the context of the preload.
 */
const preloadTimeslotSelection = (section) => {
    if (!section) {
        return;
    }

    const { selectedShipment, selectedTimeslot } = getSelectedShipmentAndSlotData();

    if (selectedShipment) {
        if (selectedShipment.selectedMethod === DELIVERY_METHODS.DOMICILIO) {
            if (!selectedTimeslot.addressId) {
                $('.add-address').trigger('click');
                reloadTimeSlotNewAddressForm($('.js-delivery-postal-code'));
            } else {
                $('input[type="radio"].js-delivery-address:checked').trigger('change');
            }
        } else if ([DELIVERY_METHODS.LOCKER, DELIVERY_METHODS.TIENDA].includes(selectedShipment.selectedMethod)) {
            $('#shippingMethodsList li a').removeClass('active');
            $(`a#tab${selectedShipment.selectedMethod}-tab`).trigger('click');

            const selectorName = selectedShipment.selectedMethod === DELIVERY_METHODS.LOCKER ? 'clickcollect' : 'instorepickup';
            const method = selectedShipment.selectedMethod === DELIVERY_METHODS.LOCKER ? 'locker' : 'store';

            $(`#${selectorName}-state`).val(selectedShipment.data.address.stateCode);
            $(`#${selectorName}-state`).trigger('change');
            $(`#${selectorName}-city`).val(selectedShipment.data.address.city);
            $(`#${selectorName}-city`).trigger('change');

            $(`#${selectorName}-${method} option`).filter(function selectItem() {
                return $(this).text() === selectedShipment.data.address[`${method}Name`];
            }).prop('selected', true);
            $(`#${selectorName}-${method}`).trigger('change');

            $('input[name="dwfrm_timeslot_address1"]').val('');
            $('input[name="dwfrm_timeslot_postalCode"]').val('');
            $('input[name="dwfrm_timeslot_city"]').val('');

            clearTimeSlotCarrousel(DELIVERY_METHODS.DOMICILIO);
        }
    }
};

module.exports = {
    updateShippingSelectors,
    reloadDeliveryTimeSlot,
    reloadClickAndCollectTimeSlot,
    reloadInStorePickUpTimeSlot,
    getSelectedShipmentAndSlotData,
    preloadTimeslotSelection
};
