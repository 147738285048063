'use strict';

/**
 * Transforms the given province name to its corresponding value.
 * @param {string} name - The name of the province.
 * @returns {number|null} The value corresponding to the province name if found, otherwise null.
 */
const transformProvinceToValue = (name) => {
    const provinces = [{key:'Araba',value:243},{key:'Albacete',value:244},{key:'Alicante',value:245},{key:'Almería',value:246},{key:'Ávila',value:247},{key:'Badajoz',value:248},{key:'Illes Balears',value:249},{key:'Barcelona',value:250},{key:'Burgos',value:251},{key:'Cáceres',value:252},{key:'Cádiz',value:253},{key:'Castelló',value:254},{key:'Ciudad Real',value:255},{key:'Córdoba',value:256},{key:'A Coruña',value:257},{key:'Cuenca',value:258},{key:'Girona',value:259},{key:'Granada',value:260},{key:'Guadalajara',value:261},{key:'Gipuzkoa',value:262},{key:'Huelva',value:263},{key:'Huesca',value:264},{key:'Jaén',value:265},{key:'León',value:266},{key:'Lleida',value:267},{key:'La Rioja',value:268},{key:'Lugo',value:269},{key:'Madrid',value:270},{key:'Málaga',value:271},{key:'Murcia',value:272},{key:'Navarra',value:273},{key:'Ourense',value:274},{key:'Asturias',value:275},{key:'Palencia',value:276},{key:'Las Palmas',value:277},{key:'Pontevedra',value:278},{key:'Salamanca',value:279},{key:'Santa Cruz de Tenerife',value:280},{key:'Cantabria',value:281},{key:'Segovia',value:282},{key:'Sevilla',value:283},{key:'Soria',value:284},{key:'Tarragona',value:285},{key:'Teruel',value:286},{key:'Toledo',value:287},{key:'Valencia',value:288},{key:'Valladolid',value:289},{key:'Vizcaya',value:290},{key:'Zamora',value:291},{key:'Zaragoza',value:292},{key:'Ceuta',value:293},{key:'Melilla',value:294}]; // eslint-disable-line
    const province = provinces.find(provincia => provincia.key === name);
    return province ? province.value : null;
};

/**
 * Retrieves the route (street name) from the given address components.
 * @param {Object[]} address - The address components to search through.
 * @returns {string|undefined} The route if found, otherwise undefined.
 */
const getRoute = (address) => {
    const field = address.find((campo) => campo.types[0] === 'route');
    return field ? field.long_name : undefined;
};

/**
 * Retrieves the street number from the given address components.
 * @param {Object[]} address - The address components to search through.
 * @returns {string|undefined} The street number if found, otherwise undefined.
 */
const getNumber = (address) => {
    const field = address.find((campo) => campo.types[0] === 'street_number');
    return field ? field.long_name : undefined;
};

/**
 * Retrieves the postal code from the given address components.
 * @param {Object[]} address - The address components to search through.
 * @returns {string|undefined} The postal code if found, otherwise undefined.
 */
const getPostalCode = (address) => {
    const field = address.find((campo) => campo.types[0] === 'postal_code');
    return field ? field.short_name : undefined;
};

/**
 * Retrieves the province name from the given address components and transforms it to a specific value.
 * @param {Object[]} address - The address components to search through.
 * @returns {string|undefined} The transformed province name if found, otherwise undefined.
 */
const getProvince = (address) => {
    const field = address.find((campo) => campo.types[0] === 'administrative_area_level_2');
    return field ? transformProvinceToValue(field.long_name) : undefined;
};

/**
 * Retrieves the city name from the given address components.
 * @param {Object[]} address - The address components to search through.
 * @returns {string|undefined} The city name if found, otherwise undefined.
 */
const getCity = (address) => {
    const field = address.find((campo) => campo.types[0] === 'locality');
    return field ? field.long_name : undefined;
};

/**
 * Initializes the Google Maps Places Autocomplete functionality on the input element with ID 'address1'.
 * Restricts the autocomplete to addresses in Spain and includes address components in the results.
 */
function initAutocomplete() {
    const address = document.getElementById('address1');

    if (address) {
        let autocomplete = new google.maps.places.Autocomplete(address, {
            componentRestrictions: { country: ['es'] },
            fields: ['address_components'],
            types: ['geocode']
        });

        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();

            const addressField = document.getElementById('address1');
            const cityField = document.getElementById('city');
            const provinceField = document.getElementById('state');
            const postalCodeField = document.getElementById('postalCode');
            const zipCodeField = document.getElementById('zipCode');
            const numberField = document.getElementById('address2');

            if (addressField) {
                const addressValue = getRoute(place.address_components);
                addressField.value = addressValue || '';
            }

            if (cityField) {
                const cityValue = getCity(place.address_components);
                cityField.value = cityValue || '';
            }

            if (provinceField) {
                const provinceValue = getProvince(place.address_components);
                provinceField.value = provinceValue || '';
            }

            const postalCodeValue = getPostalCode(place.address_components);
            if (postalCodeField) {
                postalCodeField.value = postalCodeValue || '';
            } else if (zipCodeField) {
                zipCodeField.value = postalCodeValue || '';
            }

            if (numberField) {
                const numberValue = getNumber(place.address_components);
                numberField.value = numberValue || '';
            }
        });
    }
}

/**
 * Loads a script from the given source URL and appends it to the document head.
 * Additionally, creates and appends a script element containing the definitions of several functions.
 * @param {string} src - The source URL of the script to load.
 * @param {boolean} load - True if scripts needs te be appended to the head, false otherwise
 */
const loadScript = (src, load) => {
    if (src && load) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        script.defer = true;
        document.head.appendChild(script);

        const script2 = document.createElement('script');
        script2.type = 'text/javascript';
        script2.id = 'js-google-places';
        script2.text = `
            ${transformProvinceToValue.toString()}
            ${getRoute.toString()}
            ${getNumber.toString()}
            ${getPostalCode.toString()}
            ${getProvince.toString()}
            ${getCity.toString()}
            ${initAutocomplete.toString()}
        `;
        script2.defer = true;
        document.head.appendChild(script2);
    } else {
        initAutocomplete();
    }
};

module.exports = {
    loadScript
};
