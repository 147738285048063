// SLICK SLIDER BASIC
$(".slick-slider-basic").slick({
    slidesToShow: 1,
    infinite:true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true
});

$(".slick-slider-multi").slick({
    slidesToShow: 6,
    infinite:true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    prevArrow: '<svg class="left" xmlns="http://www.w3.org/2000/svg" width="15" height="24" viewBox="0 0 15 24" fill="none"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 24L-5.24537e-07 12L12 -5.24537e-07L15 3L6 12L15 21L12 24Z" fill="black"/> </svg>',
    nextArrow: '<svg class="right" xmlns="http://www.w3.org/2000/svg" width="15" height="24" viewBox="0 0 15 24" fill="none"> <path fill-rule="evenodd" clip-rule="evenodd" d="M3 -5.24537e-07L15 12L3 24L-9.17939e-07 21L9 12L-1.31134e-07 3L3 -5.24537e-07Z" fill="black"/> </svg>',
    responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
          }
        }
    ]
});

// SLICK SLIDER MULTI
$('.slick-slider-multi-4').slick({
  slidesToShow: 4,
  infinite: true,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  dots: false,
  prevArrow: '<svg class="left" xmlns="http://www.w3.org/2000/svg" width="15" height="24" viewBox="0 0 15 24" fill="none"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 24L-5.24537e-07 12L12 -5.24537e-07L15 3L6 12L15 21L12 24Z" fill="black"/> </svg>',
  nextArrow: '<svg class="right" xmlns="http://www.w3.org/2000/svg" width="15" height="24" viewBox="0 0 15 24" fill="none"> <path fill-rule="evenodd" clip-rule="evenodd" d="M3 -5.24537e-07L15 12L3 24L-9.17939e-07 21L9 12L-1.31134e-07 3L3 -5.24537e-07Z" fill="black"/> </svg>',
  responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      }
  ]
});

$(".slick-slider-multi-5").slick({
  slidesToShow: 5,
  infinite:true,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  dots: false,
  prevArrow: '<svg class="left" xmlns="http://www.w3.org/2000/svg" width="15" height="24" viewBox="0 0 15 24" fill="none"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 24L-5.24537e-07 12L12 -5.24537e-07L15 3L6 12L15 21L12 24Z" fill="black"/> </svg>',
  nextArrow: '<svg class="right" xmlns="http://www.w3.org/2000/svg" width="15" height="24" viewBox="0 0 15 24" fill="none"> <path fill-rule="evenodd" clip-rule="evenodd" d="M3 -5.24537e-07L15 12L3 24L-9.17939e-07 21L9 12L-1.31134e-07 3L3 -5.24537e-07Z" fill="black"/> </svg>',
  responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      }
  ]
});

// slick slider space
$(".slick-slider-space").slick({
  slidesToShow: 5,
  infinite:true,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  centerPadding: '20px',
  dots: false,
  prevArrow: '<svg class="left" xmlns="http://www.w3.org/2000/svg" width="15" height="24" viewBox="0 0 15 24" fill="none"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 24L-5.24537e-07 12L12 -5.24537e-07L15 3L6 12L15 21L12 24Z" fill="black"/> </svg>',
  nextArrow: '<svg class="right" xmlns="http://www.w3.org/2000/svg" width="15" height="24" viewBox="0 0 15 24" fill="none"> <path fill-rule="evenodd" clip-rule="evenodd" d="M3 -5.24537e-07L15 12L3 24L-9.17939e-07 21L9 12L-1.31134e-07 3L3 -5.24537e-07Z" fill="black"/> </svg>',
  responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      }
  ]
});

// SLICK SLIDER BARS
$('.slick-slider-bars').slick({
  slidesToShow: 13,
  infinite: true,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  vertical: false,
  dots: false,
  prevArrow: '<svg class="left" xmlns="http://www.w3.org/2000/svg" width="15" height="24" viewBox="0 0 15 24" fill="none"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 24L-5.24537e-07 12L12 -5.24537e-07L15 3L6 12L15 21L12 24Z" fill="black"/> </svg>',
  nextArrow: '<svg class="right" xmlns="http://www.w3.org/2000/svg" width="15" height="24" viewBox="0 0 15 24" fill="none"> <path fill-rule="evenodd" clip-rule="evenodd" d="M3 -5.24537e-07L15 12L3 24L-9.17939e-07 21L9 12L-1.31134e-07 3L3 -5.24537e-07Z" fill="black"/> </svg>',
  responsive: [
      {
        breakpoint: 769,
        settings: {
          vertical: true,
        }
      }

  ]
});

// slick slider thumbs
$(function(){
	$('.slider-thumb').slick({
		autoplay: false,
		vertical: true,
		infinite: true,
		verticalSwiping: true,
		slidesPerRow: 2,
		slidesToShow: 2,
		asNavFor: '.slider-preview',
		focusOnSelect: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-up"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-down"></i></button>'
	});
	$('.slider-preview').slick({
		autoplay: false,
		vertical: true,
		infinite: true,
		slidesPerRow: 1,
		slidesToShow: 1,
		asNavFor: '.slider-thumb',
		arrows: false,
		draggable: false
	});
});

// slick delivery modal
function initializeSlider(tableClass, navClass, initialSlide) {
  $(tableClass).slick({
    asNavFor: navClass,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    adaptiveHeight: true,
    infinite: true
  });

  $(navClass).slick({
    asNavFor: tableClass,
    slidesToShow: 7,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: '0',
    focusOnSelect: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        }
      },
    ]
  });

  $('.slick-prev').on('click', function () {
    $(navClass).slick('slickPrev');
  });

  $('.slick-next').on('click', function () {
    $(navClass).slick('slickNext');
  });

  $(initialSlide).on('click', function () {
    $(navClass).slick('slickGoTo', 0);
    $(tableClass).slick('slickGoTo', 0);
  });
}
